import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Providers from './Providers'

if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {}
  
  // Hide log
  // eslint-disable-next-line no-console
  console.log = noop
  console.warn = noop
  console.error = noop
}

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
