import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'JUTC',
    lpAddresses: {
      97: '0x1b9E9F0DCFaD211D68404a09aF612e4b89649CCe', // JUTC
      56: '0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf',
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0x1b9E9F0DCFaD211D68404a09aF612e4b89649CCe', // JUTC
      56: '0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
    isTokenOnly:true
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'JUTC-BNB',
    lpAddresses: {
      97: '0x326d775A70E1096053d26E8e0b48747ce23bCeAD',
      56: '0x9f84acdecf6dbea588fdb8ab6b32306e251186b4',
    },
    tokenSymbol: 'JUTC',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 3,
    lpSymbol: 'JUTC-BUSD',
    lpAddresses: {
      97: '0xa4cfaba8443971bd4c9ece4912a772d64e4445cc',
      56: '0xf92ff44ba6d90bebb01886bc24c3cbf4d5817b47',
    },
    tokenSymbol: 'JUTC',
    tokenAddresses: {
      97: '0x1b9E9F0DCFaD211D68404a09aF612e4b89649CCe',
      56: '0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 3,
    lpSymbol: 'BUSD-BNB',
    lpAddresses: {
      97: '0xa4cfaba8443971bd4c9ece4912a772d64e4445cc',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    tokenSymbol: 'JUTC',
    tokenAddresses: {
      97: '0x1b9E9F0DCFaD211D68404a09aF612e4b89649CCe',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  
]



export default farms
