import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getSaltAddress = () => addresses.cake[chainId]
export const getCakeAddress = () => addresses.cake[chainId]
export const getMasterChefAddress = () => addresses.masterChef[chainId]
export const getMulticallAddress = () => addresses.mulltiCall[chainId]
export const getWbnbAddress = () => addresses.wbnb[chainId]
export const getLotteryAddress = () => addresses.lottery[chainId]
export const getLotteryTicketAddress = () => addresses.lotteryNFT[chainId]
export const getMigrationAddress = () => addresses.migration[chainId]
export const getROYXAddress = () => addresses.royx[chainId]
